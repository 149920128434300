import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Layout from "../core/Layout";
import { Link, navigate } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import PageTitle from "../core/PageTitle";
import { RouteComponentProps } from "@reach/router";
import React, { useEffect, useState } from "react";
import { OutboundLink } from "gatsby-plugin-google-gtag";
// import NewsletterRow from "../newsletter/NewsletterRow";
import ComponentsSearchBar from "../catalog/ComponentsSearchBar";
import MakerOSSection from "../ads/MakerOSSection";

interface SnippetsProps extends RouteComponentProps {
  pageContext: {
    snippetsArray: [
      {
        date: Date;
        name: string;
        slug: string;
        gist: string;
        gistFileName?: string;
        description: string;
        authorName: string;
        authorProfileUrl: string;
        tags: string[];
      }
    ];
    featuredJobs: any[];
  };
}

export default function Snippets(props: SnippetsProps) {
  const classes = useStyles();
  const handleSearchQueryChange = (event: any) => {
    setUserState({
      searchQuery: event,
    });
  };
  const tags = props.pageContext.snippetsArray.flatMap((snippet) => {
    return snippet.tags;
  });
  const uniqueTags = Array.from(new Set(tags.sort()));
  const [userState, setUserState] = useState({
    searchQuery: [] as string[],
  });

  useEffect(() => {
    setUserState({
      searchQuery: [],
    });
  }, []);

  return (
    <>
      <Layout
        maxWidth="md"
        pageTitle="Jetpack Compose Code Snippets"
        pageDescription="Explore concise code snippets for Jetpack Compose. Solve common Android UI challenges with our easy-to-implement solutions."
        pageSlug="/snippets"
        pageImageUrl="/snippets_seo_poster.png"
        seoImageUrl="/snippets_seo_poster.png"
      >
        <PageTitle
          header="Compose Code Snippets"
          subheader="Bite sized code snippets for common Jetpack Compose problems. Copy + Paste ready!"
        />
        <Grid container xs={12} className={classes.table}>
          <Grid item xs={12} className={classes.formSubmission}>
            Have a project you'd like to submit?{" "}
            <OutboundLink
              href="https://airtable.com/shr7HuMcSTnfgfFjd"
              target="_blank"
              className={classes.formSubmissionLink}
            >
              Fill this form
            </OutboundLink>
            , will ya!
          </Grid>
          <Grid item xs={12} md={12}>
            <div className={classes.searchBar}>
              <ComponentsSearchBar
                onChangeHandler={handleSearchQueryChange}
                componentCategories={uniqueTags}
                searchQuery={userState.searchQuery}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={4} className={classes.tableHead}>
            Name
          </Grid>
          <Grid item xs={12} md={6} className={classes.tableHead}>
            Description
          </Grid>
          <Grid item xs={12} md={2} className={classes.tableHead}>
            Author
          </Grid>
          {props.pageContext.snippetsArray
            .filter((snippet) => {
              return (
                userState.searchQuery.length === 0 ||
                snippet.tags.filter((value: string) =>
                  userState.searchQuery.includes(value)
                ).length > 0
              );
            })
            .map((snippet) => (
              <>
                <Grid item xs={12} md={4} className={classes.tableCell}>
                  <Link to={`${snippet.slug}`}>{snippet.name}</Link>
                </Grid>
                <Grid item xs={12} md={6} className={classes.tableCell}>
                  {snippet.description}
                </Grid>
                <Grid item xs={12} md={2} className={classes.tableCell}>
                  <a
                    href={snippet.authorProfileUrl}
                    target="__blank"
                    className={classes.authorProfile}
                  >
                    {snippet.authorName}
                  </a>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </>
            ))}
        </Grid>
        {/* <div className={classes.jobs}>
          <NewsletterRow />
        </div> */}
        <div className={classes.jobs}>
          <MakerOSSection />
        </div>
      </Layout>
    </>
  );
}

const useStyles = makeStyles({
  table: {
    alignItems: "center",
    justifySelf: "center",
    marginBottom: 64,
  },
  searchBar: {
    margin: 16,
  },
  tableHead: {
    backgroundColor: "lightgoldenrodyellow",
    padding: 16,
    fontWeight: 400,
    fontSize: 20,
    fontFamily: "Nunito Sans",
    color: "#222222",
    "& a": {
      color: "#4636f7",
    },
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#efefef",
    },
  },
  tableCell: {
    fontWeight: 400,
    fontSize: 20,
    fontFamily: "Nunito Sans",
    color: "#222222",
    padding: 16,
    "& a": {
      color: "#4636f7",
    },
  },
  authorProfile: {
    color: "#222222 !important",
    textDecoration: "underline",
  },
  jobs: {
    marginTop: 32,
    marginBottom: 64,
    margin: "auto",
    maxWidth: 960,
    padding: 16,
  },
  formSubmission: {
    fontWeight: 400,
    fontSize: 20,
    fontFamily: "Nunito Sans",
    color: "#222222",
    marginBottom: 16,
    paddingLeft: 16,
    paddingRight: 16,
  },
  formSubmissionLink: {
    color: "#4636f7",
  },
});
